import GetStarted from "./getStarted"
import PropTypes from "prop-types"

Hero.propTypes = {
  headline1: PropTypes.string.isRequired,
  headline2: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  renderCta: PropTypes.func,
  getStartedRouteLink: PropTypes.string,
  imageLink: PropTypes.string,
  heroIllustration: PropTypes.element,
  inputPlaceHolder: PropTypes.string,
  inputType: PropTypes.string,
}
export default function Hero({
  headline1,
  headline2,
  subText,
  renderCta,
  getStartedRouteLink,
  imageLink,
  heroIllustration,
  inputPlaceHolder,
  inputType,
}) {
  return (
    <div className="relative bg-background pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-8xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="md:mt-8 mb-2">
                <h1>
                  <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-4xl xl:text-5xl">
                    <span className="block text-gray-900 dark:text-gray-200">
                      {headline1}
                    </span>
                    <span className={"pt-2 block text-accent"}>{headline2}</span>
                  </span>
                </h1>

                <p className="mt-3 text-base text-gray-800 dark:text-gray-300 dark: sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {subText}
                </p>

                {renderCta ? (
                  renderCta()
                ) : (
                  <div className="mt-8 md:mb-24 lg:mx-0 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left ">
                    <GetStarted
                      routeLink={getStartedRouteLink}
                      inputPlaceHolder={inputPlaceHolder}
                      inputType={inputType}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            {heroIllustration ?? (
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full flex items-center">
                <img
                  className="w-full  lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={imageLink || "/images/hero-optimized.svg"}
                  alt="Inbox user interface"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
