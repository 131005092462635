import { Header } from "@common/header/headerUnit"
import Video from "@common/landing/video"

export default function CurrentsHome() {
  return (
    <section>
      <div className="text-center my-20">
        <Header.MainHeader alignment="center" variant="h5">
          Write fact-filled articles 10x faster.
        </Header.MainHeader>
      </div>
      <div className="max-w-4xl m-auto my-20">
        <Video />
      </div>
    </section>
  )
}
